"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const types_1 = require("../types");
const lodash_es_1 = require("lodash-es");
exports.getLoggerMiddleware = (hooks = [
    types_1.VxStoreHooks.endAction,
    types_1.VxStoreHooks.startAction,
    types_1.VxStoreHooks.endMutation,
    types_1.VxStoreHooks.startMutation,
    types_1.VxStoreHooks.startEffect
]) => {
    return {
        hooks,
        execute: (state, hook, payload, fnName, storeName) => {
            console.group(`${hook}: ${fnName}`);
            const stateName = storeName || 'Store state';
            console.log(`${stateName}:`, lodash_es_1.cloneDeep(state));
            console.log('Payload: ', lodash_es_1.cloneDeep(payload));
            console.groupEnd();
        }
    };
};
