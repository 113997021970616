










import Vue from 'vue';
export default Vue.extend({
  name: 'AppBtn',
  props: {
    type: String,
    icon: Array,
    size: String
  },
  data() {
    return {
      btnType: this.type || 'primary'
    };
  },
  methods: {
    getClass(): string {
      return `app-btn ${this.btnType}`;
    },
    onclick() {
      this.$emit('click');
    }
  },
  watch: {
    type(value) {
      this.btnType = value || 'primary';
    }
  }
});
