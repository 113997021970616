import { createProvider } from './apollo/vue-apollo';
import { AppRestClient } from './rest/rest-client';
import { FileUploadClient } from './file-upload/file-upload.client';

class AppClients {
  public restClient = new AppRestClient();
  public fileUploadClient = new FileUploadClient(this.restClient);
  private apolloClient = createProvider();

  get appGraphQlClient() {
    return this.apolloClient['defaultClient'];
  }
}
const appClients = new AppClients();
export const appGraphQlClient = appClients.appGraphQlClient;
export const fileUploadClient = appClients.fileUploadClient;
export const restClient = appClients.restClient;
