var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "language-selector" },
    [
      _c("b-form-select", {
        attrs: { options: _vm.options },
        on: { change: _vm.onChange },
        model: {
          value: _vm.currentLanguage,
          callback: function($$v) {
            _vm.currentLanguage = $$v
          },
          expression: "currentLanguage"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }