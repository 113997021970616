import { authenticationApi } from '@/features/authentication/api/authentication.api';
import { userStoreActions } from './store/user.store';
import { registerGlobalMiddlewares, getLoggerMiddleware } from '@/@lib/vx-store';

export const onAppInit = async () => {
  const storeMiddlewares = process.env.VUE_ENV === 'dev' ? [getLoggerMiddleware()] : [];
  registerGlobalMiddlewares(storeMiddlewares);
  const accessToken = await authenticationApi.refreshToken();
  if (!!accessToken) {
    userStoreActions.setAccessToken(accessToken);
    const user = await authenticationApi.getCurrentUser();
    userStoreActions.setCurrentUser(user);
  }
};
