<template>
  <div id="app" class="app">
    <AppNavbar />
    <div class="router-view">
      <router-view />
    </div>
  </div>
</template>

<style lang="scss">
@import './app.scss';
</style>

<script>
import AppNavbar from '@/components/navbar/navbar.vue';
export default {
  name: 'App',
  components: {
    AppNavbar
  }
};
</script>
