
















import { Component, Vue } from 'vue-property-decorator';
import router from '../../router';
import AddBtn from '../buttons/add-btn/add-btn.vue';
import LanguageSelector from './language-selector/language-selector.vue';
export default {
  name: 'AppNavbar',
  components: { AddBtn, LanguageSelector },
  methods: {
    onAddRecipeClicked: () => {
      router.push({ name: 'AddRecipe' });
    },
    goHome() {
      router.push({ name: 'CommonHome' });
    }
  }
};
