const BASE_RECIPE_PATH = '/recipe';

export const recipesRoutes = [
  {
    path: BASE_RECIPE_PATH + '/add-recipe',
    name: 'AddRecipe',
    component: () => import(/* webpackPrefetch: true, webpackChunkName: "recipe" */ '@/features/recipes/add/add-recipe.vue')
  },
  {
    path: BASE_RECIPE_PATH + '/edit-recipe/:id',
    name: 'EditRecipe',
    props: true,
    component: () => import(/* webpackPrefetch: true, webpackChunkName: "recipe" */ '@/features/recipes/edit/edit-recipe.vue')
  },
  {
    path: BASE_RECIPE_PATH + '/:id',
    name: 'GetRecipe',
    props: true,
    component: () => import(/* webpackPrefetch: true, webpackChunkName: "recipe" */ '@/features/recipes/get/get-recipe.vue')
  }
];
