"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const effects_1 = require("./effects");
const types_1 = require("./types");
const middleware_1 = require("./middlewares/middleware");
const lodash_es_1 = require("lodash-es");
class Store {
    constructor(initState, middlewares, storeName) {
        this.storeMiddlewares = [];
        this.mutations = [];
        this.getState = () => this.state;
        if (!Array.isArray(middlewares)) {
            throw new Error(`You must provide middleware into an array: ${middlewares}`);
        }
        this.name = storeName || lodash_es_1.uniqueId('vx-store-');
        this.storeMiddlewares = [...middlewares];
        this.state = vue_1.default.observable(lodash_es_1.cloneDeep(initState));
    }
    createMutation(fn, mutationName) {
        const storeMutation = (state, payload) => fn(state, payload);
        Object.defineProperty(storeMutation, 'name', { value: mutationName });
        this.mutations.push(storeMutation);
        return storeMutation;
    }
    commit(type, payload) {
        const matchingMutation = this.mutations.find((m) => m === type);
        if (!matchingMutation) {
            throw new Error(`There is no matching mutation: ${type}`);
        }
        middleware_1.applyMiddlewares(this, this.state, types_1.VxStoreHooks.startMutation, payload, matchingMutation.name);
        matchingMutation(this.state, payload);
        middleware_1.applyMiddlewares(this, this.state, types_1.VxStoreHooks.endMutation, payload, matchingMutation.name);
    }
    createAction(actionCreator, actionName) {
        const actionFn = async (payload) => {
            const context = { state: this.getState(), commit: this.commit.bind(this) };
            const effects = effects_1.getEffectsToApply(actionFn);
            middleware_1.applyMiddlewares(this, this.state, types_1.VxStoreHooks.startAction, payload, actionName);
            await actionCreator(context, payload);
            middleware_1.applyMiddlewares(this, this.state, types_1.VxStoreHooks.endAction, payload, actionName);
            effects_1.applyEffects(this, this.state, effects, payload);
        };
        Object.defineProperty(actionFn, 'name', { value: actionName });
        return actionFn;
    }
    registerStoreMiddleware(middlewares) {
        middlewares.forEach((m) => {
            this.storeMiddlewares.push(m);
        });
    }
}
exports.Store = Store;
exports.createVxStore = (initState, middlewares = [], storeName) => new Store(initState, middlewares, storeName);
