import axios from 'axios';

interface RestClientConfig {
  url?: string;
  method?: string;
  baseURL?: string;
  transformRequest?: Function[];
  transformResponse?: Function[];
  headers?: any;
  params?: any;
  paramsSerializer?: (params: any) => string;
  data?: any;
  timeout?: number;
  timeoutErrorMessage?: string;
  withCredentials?: boolean;
  responseType?: ResponseType;
  xsrfCookieName?: string;
  xsrfHeaderName?: string;
  onUploadProgress?: (progressEvent: any) => void;
  onDownloadProgress?: (progressEvent: any) => void;
  maxContentLength?: number;
  validateStatus?: (status: number) => boolean;
  maxRedirects?: number;
  socketPath?: string | null;
  httpAgent?: any;
  httpsAgent?: any;
}

export class AppRestClient {
  async post(url: string, data?: any, config?: RestClientConfig): Promise<any> {
    return (await axios.post(url, data, config as any)).data;
  }
}
