








import Vue from 'vue';
import Multiselect from 'vue-multiselect';
import { i18nGateway } from '@/core/i18n/i18n';
export default Vue.extend({
  name: 'LanguageSelector',
  components: { Multiselect },
  data() {
    return {
      i18n: i18nGateway.geti18nInstance(),
      currentLanguage: i18nGateway.getCurrentLocale(),
      availablesLanguages: i18nGateway.getAvailableLanguages()
    };
  },
  computed: {
    options() {
      return this.availablesLanguages.map((l: string) => {
        return {
          value: l,
          text: l[0].toUpperCase() + l.slice(1)
        };
      });
    }
  },
  methods: {
    onChange(lang: string) {
      i18nGateway.changeLocale(lang);
    }
  },
  watch: {
    'i18n.locale'(value) {
      this.currentLanguage = value;
    }
  }
});
