"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var RecipeType;
(function (RecipeType) {
    RecipeType["APETIZER"] = "Apetizer";
    RecipeType["SAUCE"] = "Sauce";
    RecipeType["SIDE_DISH"] = "Side dish";
    RecipeType["STARTER"] = "Starter";
    RecipeType["MAIN"] = "Main dish";
    RecipeType["DESSERT"] = "Dessert";
    RecipeType["DRINK"] = "Drink";
})(RecipeType = exports.RecipeType || (exports.RecipeType = {}));
exports.RECIPE_TYPE = [
    RecipeType.APETIZER,
    RecipeType.STARTER,
    RecipeType.MAIN,
    RecipeType.DESSERT,
    RecipeType.DRINK,
    RecipeType.SAUCE,
    RecipeType.SIDE_DISH
];
var RecipePrice;
(function (RecipePrice) {
    RecipePrice["CHEAP"] = "Cheap";
    RecipePrice["MEDIUM"] = "Medium";
    RecipePrice["EXPENSIVE"] = "Expensive";
})(RecipePrice = exports.RecipePrice || (exports.RecipePrice = {}));
exports.RECIPE_PRICE = [RecipePrice.CHEAP, RecipePrice.MEDIUM, RecipePrice.EXPENSIVE];
var RecipeDifficulty;
(function (RecipeDifficulty) {
    RecipeDifficulty["EASY"] = "Easy";
    RecipeDifficulty["MEDIUM"] = "Medium";
    RecipeDifficulty["HARD"] = "Hard";
})(RecipeDifficulty = exports.RecipeDifficulty || (exports.RecipeDifficulty = {}));
exports.RECIPE_DIFFICULTY = [RecipeDifficulty.EASY, RecipeDifficulty.MEDIUM, RecipeDifficulty.HARD];
var QtyUnit;
(function (QtyUnit) {
    QtyUnit["gram"] = "g";
    QtyUnit["kilogram"] = "kg";
    QtyUnit["cup"] = "cup";
    QtyUnit["pinch"] = "pinch";
    QtyUnit["teaSpoon"] = "Tea spoon";
    QtyUnit["soupSpoon"] = "Soup spoon";
    QtyUnit["milliliter"] = "mL";
    QtyUnit["liter"] = "L";
})(QtyUnit = exports.QtyUnit || (exports.QtyUnit = {}));
exports.QTY_UNITS = [
    QtyUnit.gram,
    QtyUnit.kilogram,
    QtyUnit.cup,
    QtyUnit.teaSpoon,
    QtyUnit.soupSpoon,
    QtyUnit.milliliter,
    QtyUnit.liter
];
