"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const types_1 = require("./types");
const middleware_1 = require("./middlewares/middleware");
const registeredEffects = [];
function getEffectsToApply(trigger) {
    return registeredEffects.filter((effect) => {
        return effect.triggers.some((t) => t === trigger);
    });
}
exports.getEffectsToApply = getEffectsToApply;
function applyEffects(store, state, effects, payload) {
    effects.forEach((e) => {
        middleware_1.applyMiddlewares(store, state, types_1.VxStoreHooks.startEffect, payload, e.effect.name);
        e.effect(payload);
    });
}
exports.applyEffects = applyEffects;
function registerEffect(effect, effectName) {
    const appEffect = Object.assign({}, effect);
    Object.defineProperty(appEffect.effect, 'name', { value: effectName });
    registeredEffects.push(appEffect);
    return appEffect;
}
exports.registerEffect = registerEffect;
function removeEffect(effect) {
    const effectIndex = registeredEffects.findIndex((e) => e === effect);
    registeredEffects.splice(effectIndex, 1);
}
exports.removeEffect = removeEffect;
function removeEffects(effects) {
    if (Array.isArray(effects)) {
        effects.forEach((e) => removeEffect(e));
        return;
    }
    throw new Error('To remove effects in bulk you need to provide an array of effects');
}
exports.removeEffects = removeEffects;
