"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const globalMiddlewares = [];
exports.registerGlobalMiddlewares = (middlewares) => {
    middlewares.forEach((m) => {
        globalMiddlewares.push(m);
    });
};
exports.applyMiddlewares = (store, state, hook, payload, fnName) => {
    const relatedGlobalMiddlewares = globalMiddlewares.filter((m) => m.hooks.some((h) => h === hook));
    const relatedStoreMiddlewares = store.storeMiddlewares.filter((m) => m.hooks.some((h) => h === hook));
    const middlewares = [...relatedGlobalMiddlewares, ...relatedStoreMiddlewares];
    middlewares.forEach((m) => {
        m.execute(state, hook, payload, fnName, store.name);
    });
};
