"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const RECIPE_AVATAR_PREFIX = 'recipe-avatar-';
exports.getRecipeAvatarFilename = (recipeId) => {
    return !!recipeId ? RECIPE_AVATAR_PREFIX + recipeId : undefined;
};
exports.getRecipeAvatarUrl = (bucketUrl, recipeId) => {
    return bucketUrl + exports.getRecipeAvatarFilename(recipeId);
};
exports.extractRecipeIdFromAvatarName = (avatarFilename) => {
    return avatarFilename.substring(RECIPE_AVATAR_PREFIX.length);
};
