export const authenticationRoutes = [
  {
    path: '/signup',
    name: 'Signup',
    component: () => import(/* webpackPrefetch: true, webpackChunkName: "authentication" */ '@/features/authentication/signup/signup.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackPrefetch: true, webpackChunkName: "authentication" */ '@/features/authentication/login/login.vue')
  }
];
