export const homeRoutes = [
  {
    path: '/home',
    name: 'CommonHome',
    component: () => import(/* webpackPrefetch: true, webpackChunkName: "home" */ '@/features/home/common/common-home.vue')
  },
  {
    path: '/',
    redirect: { name: 'CommonHome' }
  },
  {
    path: '',
    redirect: { name: 'CommonHome' }
  }
  // {
  //   path: '/home/:id',
  //   name: 'UserHome',
  //   props: true,
  //   component: () => import(/* webpackPrefetch: true, webpackChunkName: "home" */ '@/features/recipes/edit/edit-recipe.vue')
  // }
];
