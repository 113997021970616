export const searchi18nEn = {
  recipe: {
    search: {
      placeholder: 'Search a recipe by name or ingredient'
    },
    searchSelect: {
      placeholder: 'Choose a recipe'
    }
  }
};
