import { createVxStore, createGetter, VxActionContext } from '@/@lib/vx-store';
import { User } from '@/@lib/shared-mlk';

export interface UserState {
  currentUser: User;
  accessToken: string;
}

const initialState: UserState = {
  currentUser: undefined,
  accessToken: undefined
};

export const userStore = createVxStore(initialState);

const setCurrentUserMutation = userStore.createMutation((state: UserState, user: User) => {
  state.currentUser = user;
}, 'setCurrentUser');
const setAccessTokenMutation = userStore.createMutation((state: UserState, token: string) => {
  state.accessToken = token;
}, 'setAccessToken');
const setCurrentUser = userStore.createAction(({ commit }: VxActionContext<UserState>, user: User) => {
  commit(setCurrentUserMutation, user);
}, 'setCurrentUser');
const setAccessToken = userStore.createAction(({ commit }: VxActionContext<UserState>, token: string) => {
  commit(setAccessTokenMutation, token);
}, 'setAccessToken');
const selectCurrentUser = createGetter(userStore.getState, (state) => state.currentUser);
const selectAccessToken = createGetter(userStore.getState, (state) => state.accessToken);

export const userStoreActions = {
  setCurrentUser,
  setAccessToken
};

export const userStoreGetters = {
  selectCurrentUser,
  selectAccessToken
};
