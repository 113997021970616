import { recipei8nEn } from '@/features/recipes/recipe.i18n.en';
import { componentsi18nEn } from '@/components/components.i18n.en';
import { authenticationI18nEn } from '@/features/authentication/authentication.en';
import { homei18nEn } from '@/features/home/home.i18n.en';
import { searchi18nEn } from '@/features/search/search.i18n.en';

export default {
  ...recipei8nEn,
  ...componentsi18nEn,
  ...authenticationI18nEn,
  ...homei18nEn,
  ...searchi18nEn
};
