import VueI18n from 'vue-i18n';
import Vue from 'vue';
import { I18nGateway } from './i18n-gateway';
import en from './languages/en';
const messages = {
  en
};

const setupI18n = (): I18nGateway => {
  Vue.use(VueI18n);

  const i18n = new VueI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en',
    messages // set locale messages
  });

  const i18nGateway = new I18nGateway(i18n);
  const initialLanguage = navigator.language;
  i18nGateway.changeLocale(initialLanguage);

  return i18nGateway;
};
setupI18n();
export const i18nGateway = setupI18n();
