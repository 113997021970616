"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function createGetter(...args) {
    const fns = args.slice(0, args.length - 1);
    const selector = args[args.length - 1];
    return () => {
        const states = fns.map((fn) => fn());
        return selector(...states);
    };
}
exports.createGetter = createGetter;
