"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REFRESH_TOKEN_KEY = 'MLK_REFRESH_TOKEN';
exports.ACCESS_TOKEN_KEY = 'MLK_ACCESS_TOKEN';
exports.getParsedCookies = (cookies = '') => {
    return cookies.split(';').reduce((parsedCookies, cookie) => {
        const splittedCookie = cookie.split('=');
        return Object.assign(Object.assign({}, parsedCookies), { [splittedCookie[0]]: splittedCookie[1] });
    }, {});
};
