import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import { recipesRoutes } from '@/features/recipes/recipes.routes';
import { authenticationRoutes } from '@/features/authentication/authentication.routes';
import { homeRoutes } from '@/features/home/home.route';

Vue.use(VueRouter);

const routes = [
  ...recipesRoutes,
  ...authenticationRoutes,
  ...homeRoutes // Must be last because there is default redirection
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
