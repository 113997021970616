import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import './core/icons/icon';
import { BootstrapVue } from 'bootstrap-vue';
import { i18nGateway } from './core/i18n/i18n';
import { onAppInit } from './core/app-bootstrap';

// Install BootstrapVue
Vue.use(BootstrapVue);

Vue.config.productionTip = false;
new Vue({
  router,
  i18n: i18nGateway.geti18nInstance(),
  render: h => h(App)
}).$mount('#app');

onAppInit();
