var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-navbar" }, [
    _c("div", { staticClass: "app-title", on: { click: _vm.goHome } }, [
      _c("span", [_vm._v("My Little Kitchen")])
    ]),
    _c(
      "div",
      { staticClass: "navbar-actions-container" },
      [
        _c("language-selector"),
        _c("add-btn", {
          staticClass: "navbar-add-btn",
          attrs: { type: "alternate" },
          on: { click: _vm.onAddRecipeClicked }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }