var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("app-btn", {
    attrs: { type: _vm.type, icon: ["fas", "plus"], size: _vm.size },
    on: { click: _vm.onclick }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }