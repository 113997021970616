import { ApolloLink } from 'apollo-link';
import { i18nGateway } from '@/core/i18n/i18n';
export const langMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'user-lang': i18nGateway.getCurrentLocale()
    }
  }));
  return forward(operation);
});
