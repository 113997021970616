import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUndo,
  faRedo,
  faPlus,
  faBold,
  faItalic,
  faUnderline,
  faParagraph,
  faListUl,
  faListOl,
  faTrash,
  faCog,
  faPen,
  faArrowLeft,
  faArrowRight,
  faQuoteLeft,
  faUtensils,
  faEye,
  faEyeSlash,
  faSearch
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';
library.add(
  faUndo,
  faRedo,
  faPlus,
  faBold,
  faItalic,
  faUnderline,
  faParagraph,
  faListUl,
  faListOl,
  faTrash,
  faCog,
  faSearch,
  faEye,
  faEyeSlash,
  faPen,
  faArrowLeft,
  faArrowRight,
  faQuoteLeft,
  faUtensils
);
Vue.component('font-awesome-icon', FontAwesomeIcon);
