import { AppRestClient } from '../rest/rest-client';

const FILE_UPLOAD_URL = process.env.VUE_APP_FILE_UPLOAD_URL || 'http://localhost:3000/';

export class FileUploadClient {
  constructor(private restClient: AppRestClient) {}

  async uploadSingleFile(url: string, file: File, filename: string) {
    let formData = new FormData();
    formData.append('file', file, filename);
    return await this.restClient.post(FILE_UPLOAD_URL + url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
}
