import SignupMutation from './graphql/signup.gql';
import LoginMutation from './graphql/login.gql';
import RefreshTokenQuery from './graphql/refresh-token.gql';
import GetCurrentUSerQuery from './graphql/get-current-user.gql';
import { User } from '@/@lib/shared-mlk';
import { appGraphQlClient } from '@/api/app-clients';
import { extractGraphqlErrorMessage } from '@/api/apollo/vue-apollo.utils';

const signup = async (user: Partial<User>): Promise<string> => {
  try {
    const result = await appGraphQlClient.mutate({
      mutation: SignupMutation,
      variables: {
        user
      }
    });
    return result.data.signup.accessToken;
  } catch (error) {
    throw new Error(extractGraphqlErrorMessage(error));
  }
};

const login = async (user: Partial<User>): Promise<string> => {
  try {
    const result = await appGraphQlClient.mutate({
      mutation: LoginMutation,
      variables: {
        user
      }
    });
    return result.data.login.accessToken;
  } catch (error) {
    throw new Error(extractGraphqlErrorMessage(error));
  }
};

const refreshToken = async (): Promise<string> => {
  try {
    const result = await appGraphQlClient.query({
      query: RefreshTokenQuery
    });
    return result.data.refreshToken.accessToken;
  } catch (error) {
    return undefined;
  }
};

const getCurrentUser = async (): Promise<User> => {
  const result = await appGraphQlClient.query({
    query: GetCurrentUSerQuery
  });
  return result.data.getCurrentUser;
};

export const authenticationApi = {
  signup,
  login,
  refreshToken,
  getCurrentUser
};
