import { RecipeType, RecipePrice, RecipeDifficulty, QTY_UNITS, QtyUnit } from '@/@lib/shared-mlk';

export const recipei8nEn = {
  recipe: {
    addRecipe: {
      title: 'Add a recipe',
      save: 'Create recipe'
    },
    editRecipe: {
      title: 'Edit a recipe',
      save: 'Save recipe'
    },
    getRecipe: {
      preparationTime: 'Preparation time',
      bakingTime: 'Baking time',
      people: 'Persons'
    },
    baseRecipe: {
      avatar: {
        placeholder: 'Upload a photo',
        'drop-placeholder': 'Choose a photo or drop it here...'
      },
      recipeName: {
        label: 'Recipe name',
        placeholder: 'Enter a yummy name',
        error: 'You need to enter a yummy name'
      },
      peopleCount: {
        label: 'How many people ?',
        error: 'You need enter a number between 1 and 10'
      },
      preparationTime: {
        label: 'Preparation time',
        placeholder: 'Preparation time in minutes',
        error: {
          required: 'You need to set a preparation time',
          minMax: 'You need enter a valid time in minutes'
        }
      },
      bakingTime: {
        label: 'Baking time (Optional)',
        placeholder: 'Baking time in minutes',
        error: 'You need enter a valid time in minutes'
      },
      type: {
        label: 'Type',
        error: 'You need choose a type',
        options: {
          [RecipeType.DRINK]: 'Drink',
          [RecipeType.MAIN]: 'Main dish',
          [RecipeType.STARTER]: 'Starter',
          [RecipeType.APETIZER]: 'Apetizer',
          [RecipeType.SIDE_DISH]: 'Side dish',
          [RecipeType.SAUCE]: 'Sauce',
          [RecipeType.DESSERT]: 'Dessert'
        }
      },
      price: {
        label: 'Price range',
        error: 'You need choose a price range',
        options: {
          [RecipePrice.CHEAP]: 'Cheap',
          [RecipePrice.MEDIUM]: 'Medium',
          [RecipePrice.EXPENSIVE]: 'Expensive'
        }
      },
      difficulty: {
        label: 'Difficulty',
        error: 'You need choose a difficulty',
        options: {
          [RecipeDifficulty.EASY]: 'Easy',
          [RecipeDifficulty.MEDIUM]: 'Medium',
          [RecipeDifficulty.HARD]: 'Hard'
        }
      }
    },
    ingredients: {
      title: 'What ingredients ?',
      error: {
        min: 'You need at least an ingredient'
      },
      ingredient: {
        name: 'Name',
        qty: 'Quantity (Optional)',
        qtyUnit: "What's the unit ? (Optional)",
        qtyUnitOptions: {
          [QtyUnit.cup]: 'Cup',
          [QtyUnit.gram]: 'Gram',
          [QtyUnit.kilogram]: 'Kilogram',
          [QtyUnit.milliliter]: 'milli-Liter',
          [QtyUnit.liter]: 'Liter',
          [QtyUnit.pinch]: 'Pinch',
          [QtyUnit.soupSpoon]: 'Soup Spoon',
          [QtyUnit.teaSpoon]: 'Tea Spoon'
        },
        errorName: 'A name is required',
        errorQty: 'Enter a valid quantity'
      }
    },
    instructions: {
      title: 'Comment cuisiner tout ça ?'
    },
    steps: {
      step1: "What's your recipe ?",
      step2: 'Ingredients',
      step3: 'Instructions'
    }
  }
};
