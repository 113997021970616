"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var VxStoreHooks;
(function (VxStoreHooks) {
    VxStoreHooks["startAction"] = "BEFORE_ACTION";
    VxStoreHooks["endAction"] = "AFTER_ACTION";
    VxStoreHooks["startMutation"] = "BEFORE_MUTATION";
    VxStoreHooks["endMutation"] = "AFTER_MUTATION";
    VxStoreHooks["startEffect"] = "START_EFFECT";
})(VxStoreHooks = exports.VxStoreHooks || (exports.VxStoreHooks = {}));
