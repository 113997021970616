import VueI18n from 'vue-i18n';

export class I18nGateway {
  private loadedLanguages = ['en'];
  private availableLanguages = ['fr', 'en'];

  constructor(private i18n: VueI18n) {}

  geti18nInstance(): VueI18n {
    return this.i18n;
  }

  getCurrentLocale(): string {
    return this.i18n.locale;
  }

  getAvailableLanguages(): string[] {
    return this.availableLanguages;
  }

  async changeLocale(lang: string): Promise<any> {
    const locale = lang.split('-')[0];
    const currentLocale = this.i18n.locale;
    if (!this.isLanguageSupportedIntheApp(locale)) {
      return;
    }
    if (locale !== currentLocale && !this.isLanguageAlreadyLoaded(locale)) {
      await this.lazyLoadLanguage(locale);
    }
    return this.updateLanguage(locale);
  }

  async lazyLoadLanguage(lang: string): Promise<any> {
    return import(/* webpackChunkName: "lang-[request]" */ `@/core/i18n/languages/${lang}`).then(messages => {
      this.i18n.setLocaleMessage(lang, messages.default);
      this.loadedLanguages.push(lang);
    });
  }

  private isLanguageSupportedIntheApp(locale: string): boolean {
    return this.availableLanguages.some(l => l === locale);
  }

  private isLanguageAlreadyLoaded(locale: string): boolean {
    return this.loadedLanguages.some(l => l === locale);
  }

  private updateLanguage(lang: string): void {
    this.i18n.locale = lang;
  }
}
