var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: _vm.getClass(),
      attrs: { type: "button" },
      on: { click: _vm.onclick }
    },
    [
      _c("font-awesome-icon", {
        staticClass: "app-btn-icon",
        attrs: { icon: _vm.icon, size: _vm.size }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }