export const authenticationI18nEn = {
  login: {
    welcome: 'Glad to see you back',
    submit: 'Login',
    email: ' Username (email)',
    password: 'Password',
    callError: {
      login: 'Wrong combination login / password'
    }
  },
  signup: {
    welcome: 'Welcome to the Little Kitchen community',
    submit: 'Signup',
    firstname: 'Firstname',
    lastname: 'Lastname',
    email: 'Email',
    password: 'Password',
    errorRequired: 'This field is required',
    errorEmail: 'You must enter a valid email',
    errorPassword: {
      base: 'Password must be as following:',
      lengthRule: 'Between 8 and 30 characters',
      caseRule: 'Contain at least one lowercase and one uppercase character',
      numberRule: 'Contain at least one number',
      specialRule: 'Contain at least one special character (!@#$%^*)'
    },
    callError: {
      emailInUse: 'Email is already in use',
      other: 'An error occured, please try again later'
    }
  }
};
