




import Vue from 'vue';
import AppBtn from '../app-btn/app-btn.vue';

export default Vue.extend({
  name: 'AddBtn',
  components: { AppBtn },
  props: {
    type: String,
    size: String
  },
  data() {
    return {
      btnType: this.type || 'primary'
    };
  },
  methods: {
    onclick() {
      this.$emit('click');
    }
  },
  watch: {
    type(value) {
      this.btnType = value || 'primary';
    }
  }
});
